
import { defineComponent, PropType } from "vue";
import { Leg, Route } from "@/typings/RoutePlanner";

export default defineComponent({
  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
    leg: {
      type: Object as PropType<Leg>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatTime(time: string): string {
      return this.$moment(time).format("HH:mm");
    },
  },
});
