
import { defineComponent } from "vue";
import { WeatherAlert as WeatherAlertsInterface } from "@/typings/Weather";

export default defineComponent({
  data: () => ({
    alerts: [] as WeatherAlertsInterface[],
    showAllDetails: false,
  }),
  mounted() {
    this.loadWeatherWarnings();
  },
  methods: {
    getAlertDescription(alert: WeatherAlertsInterface) {
      if (alert.sub_category === "hail") {
        return this.getAlertText(alert.severity, alert.sub_category);
      }

      return alert.description;
    },
    loadWeatherWarnings() {
      this.$http.get("api/v1/weather/alerts?active=1").then((response) => {
        const alertData = response.data.data;
        this.alerts = alertData
          .filter((alert: WeatherAlertsInterface) => this.isValidAlert(alert))
          .map((alert: WeatherAlertsInterface) => ({
            id: alert.id,
            category: alert.sub_category,
            sub_category: alert.sub_category,
            description: alert.description,
            severity: alert.severity,
            icon: this.mapWeatherEventIcon(alert.sub_category),
            valid_from: alert.valid_from,
            valid_to: alert.valid_to,
          }));
      });
    },
    getAlertTypeText(subCategory: string) {
      if (!this.$te("weather.alerts.weather_event." + subCategory)) {
        return;
      }

      return this.$t("weather.alerts.weather_event." + subCategory);
    },
    getAlertText(severity: string, weatherEvent: string) {
      const severityColor = this.$t(
        `weather.alerts.severity_color.${severity}`,
      );
      const eventText = this.$tc(
        `weather.alerts.weather_event.${weatherEvent}`,
        2,
      );
      return this.$t("weather.alerts.text", {
        severity_color: severityColor,
        weather_event: eventText,
      });
    },
    toggleAllAlertDetails() {
      this.showAllDetails = !this.showAllDetails;
    },
    mapWeatherEventIcon(subCategory: string) {
      switch (subCategory) {
        case "rainfall":
          return "rainfall";
        case "snow":
          return "cloudy-snowing";
        case "wind":
          return "wind";
        case "thunderstorms":
          return "thunderstorm";
        case "fireRisk":
          return "fire-danger";
        case "lowTemperature":
          return "thermometer-loss";
        case "highTemperature":
          return "thermometer-add";
        case "avalancheRisk":
          return "avalanche";
        case "hail":
          return "hail";
        case "ice":
          return "black-ice";
      }
    },
    getSeverityColor(severity: string) {
      if (severity === "high") {
        return "orange";
      } else if (severity === "critical") {
        return "red";
      }

      return "black";
    },
    isValidAlert(alert: WeatherAlertsInterface): boolean {
      const isValidSeverity = ["critical", "high"].includes(alert.severity);
      const isValidCategory = alert.category === "weather";
      const isValidSubCategory = [
        "wind",
        "rainfall",
        "thunderstorms",
        "snow",
        "ice",
        "highTemperature",
        "lowTemperature",
        "fireRisk",
        "avalancheRisk",
        "hail",
      ].includes(alert.sub_category);

      return isValidSeverity && isValidCategory && isValidSubCategory;
    },
  },
});
