import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-float-label rounded-input icon error-label mb-0 flex-1 w-full relative" }
const _hoisted_2 = { class: "flex align-items-center text-base" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "text-600" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_AutoComplete, _mergeProps(_ctx.$attrs, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      "input-id": _ctx.label,
      suggestions: _ctx.data,
      "option-label": _ctx.formatAddress,
      delay: 500,
      loading: _ctx.isLoading,
      onComplete: _ctx.complete,
      class: ["w-full", { 'pl-10': _ctx.$slots.icon }]
    }), {
      option: _withCtx((props) => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent('IconMapPin'), { class: "mr-3" })),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(props.option.name) + " " + _toDisplayString(props.option.houseNum), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(props.option.city), 1)
          ])
        ])
      ]),
      _: 1
    }, 16, ["model-value", "input-id", "suggestions", "option-label", "loading", "onComplete", "class"]),
    _renderSlot(_ctx.$slots, "icon"),
    _createElementVNode("label", { for: _ctx.label }, _toDisplayString(_ctx.label), 9, _hoisted_5)
  ]))
}