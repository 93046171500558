
import { defineComponent, PropType } from "vue";
import AutoComplete from "primevue/autocomplete";
import type { AutoCompleteCompleteEvent } from "primevue/autocomplete";
import type { RouteLocation } from "@/typings/RoutePlanner";
import IconMapPin from "~icons/tabler/map-pin";

export default defineComponent({
  inheritAttrs: false,

  components: {
    AutoComplete,
    IconMapPin,
  },

  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    modelValue: {
      type: [String, Object, null] as PropType<string | RouteLocation | null>,
      required: true,
    },
  },

  emits: {
    select: (value: RouteLocation | null) => value || !value,
    "update:modelValue": (value: string | RouteLocation | null) =>
      value || !value,
  },

  data: () => ({
    query: null as string | null,
    data: undefined as RouteLocation[] | undefined,
    isLoading: false,
  }),

  watch: {
    modelValue(value: string | RouteLocation | null) {
      if (typeof value !== "string") {
        this.$emit("select", value);
      }
    },
  },

  methods: {
    async getAddresses() {
      if (!this.query) {
        return undefined;
      }

      const response = await this.$maas.get("api/v1/locations/search", {
        params: {
          search: this.query,
        },
      });

      return response.data;
    },
    async complete(event: AutoCompleteCompleteEvent) {
      if (event.query) {
        this.query = event.query;
        this.isLoading = true;
        this.data = await this.getAddresses();
        this.isLoading = false;
      }
    },
    formatAddress(address: RouteLocation) {
      if (!address.name) {
        return "";
      }

      return `${address.name}${address.houseNum ? ` ${address.houseNum}` : ""}${
        address.city ? `, ${address.city}` : ""
      }`;
    },
  },
});
