
import { CurrentWeatherModel } from "@/models/weather/Weather";
import { CurrentWeather, Weather } from "@/typings/Weather";
import { PropType, defineComponent } from "vue";
import Divider from "primevue/divider";
import { WeatherAlert as WeatherAlertsInterface } from "@/typings/Weather";
import IconAlert from "~icons/tabler/alert-triangle";

export default defineComponent({
  props: {
    payload: {
      type: Object as PropType<{
        currently: CurrentWeather;
        daily: {
          data: Array<Weather>;
        };
        alerts: {
          data: Array<WeatherAlertsInterface>;
        };
      }>,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    forecastData() {
      return this.payload.daily.data.slice(0, 3);
    },
    currentWeather() {
      return new CurrentWeatherModel(this.payload.currently);
    },
    alerts() {
      return this.payload.alerts.data
        .filter((alert: WeatherAlertsInterface) => this.isValidAlert(alert))
        .map((alert: WeatherAlertsInterface) => ({
          category: alert.sub_category,
          sub_category: alert.sub_category,
          severity: alert.severity,
        }));
    },
    severityColor() {
      return this.alerts.some((alert) => alert.severity === "critical")
        ? "red"
        : "orange";
    },
  },
  methods: {
    isValidAlert(alert: WeatherAlertsInterface): boolean {
      const isValidSeverity = ["critical", "high"].includes(alert.severity);
      const isValidCategory = alert.category === "weather";
      const isValidSubCategory = [
        "wind",
        "rainfall",
        "thunderstorms",
        "snow",
        "ice",
        "highTemperature",
        "lowTemperature",
        "fireRisk",
        "avalancheRisk",
        "hail",
      ].includes(alert.sub_category);

      return isValidSeverity && isValidCategory && isValidSubCategory;
    },
  },
  components: { Divider, IconAlert },
});
